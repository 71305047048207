/* Header Bar */

.title {
  display: inline-block;
  background-image: linear-gradient(90deg, #ff0080, #8080ff, #00ffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.appBarSpace {
  padding: 5em;
}

.appBar {
  background-color: #aa55ff;
}

/* Sidebar */

.selfie {
  margin: 2em 4em 1em;
  padding: 2px;
  border: 4px solid #aa55ff;
}

.icon {
  color: #00ffff;
}

.contacts-container {
  margin-left: auto;
  margin-right: auto;
}

.name {
  margin-top: 2em;
  font-size: 20px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.divider-about {
  color: #aa55ff;
  background-color: #aa55ff;
  margin: 2em 2em;
  height: 0.5px;
}

.list {
  color: black;
  text-align: center;
}

.list-item {
  margin: 1em;
}

.links-container {
  padding: 0;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}

.rainbow:hover {
  -webkit-animation: rainbow 3s infinite;
  animation: rainbow 3s infinite;
}
@-webkit-keyframes rainbow {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    color: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}

@keyframes rainbow {
  0% {
    color: #ff0000;
  }
  10% {
    color: #ff8000;
  }
  20% {
    color: #ffff00;
  }
  30% {
    color: #80ff00;
  }
  40% {
    color: #00ff00;
  }
  50% {
    color: #00ff80;
  }
  60% {
    color: #00ffff;
  }
  70% {
    color: #0080ff;
  }
  80% {
    color: #0000ff;
  }
  90% {
    color: #8000ff;
  }
  100% {
    color: #ff0080;
  }
}

/* Cards */

.grid-items {
  display: grid;
  padding: 4%;
  grid-template-columns: 100%;
  grid-column-gap: 0;
  grid-row-gap: 20px;
  align-items: start;
}

@media only screen and (min-width: 700px) {
  .grid-items {
    grid-template-columns: 50% 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .grid-items {
    grid-template-columns: 33% 33% 33%;
  }
}

/* About */

.info {
  color: darkmagenta;
  font-family: 'Courier New', Courier, monospace;
}

/* Experience and Education */

.card {
  margin: 2em;
  background-color: #00ffff;
  border-radius: 15px;
  box-shadow: 6px 6px #d4d3d3;
}

.img {
  height: 10em;
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.expandOpen {
  transform: rotate(180deg);
}

.details {
  text-align: end;
  margin-right: 1em;
}

/* Arts and Crafts */

.filter-button {
  margin: 1em !important;
}

.react-images__view-image {
  max-height: 90vh !important;
}
